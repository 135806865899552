import axios from "axios";
import { config } from "../../config";
import { userPreferencesStatusPath } from "../../paths";

export interface UserPreferencesStatusPayload {
  hasUsedPreferences: boolean;
  lastUpdatedAt: string;
}

export const fetchUserPreferencesStatus =
  (): Promise<UserPreferencesStatusPayload> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(userPreferencesStatusPath, {
          baseURL: config.baseURL,
        });

        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });

export default fetchUserPreferencesStatus;
