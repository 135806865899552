import { PATH_CORP_FAQ } from "@capone/common";

export const TERMS_AND_CONDITIONS_DESKTOP =
  "Capital One Travel terms & conditions";
export const TERMS_AND_CONDITIONS_MOBILE = ["Travel terms", "& conditions"];
export const TERMS_OF_USE_DESKTOP = "Site terms of use";
export const TERMS_OF_USE_MOBILE = ["Site terms", "of use"];
export const PRIVACY = "Privacy";
export const FAQS = "FAQs";

export const LEGAL = (year: number) => {
  return `© ${year} Capital One`;
};
export const POWERED_BY = "Powered by";

export const PRIVACY_LINK = "https://www.capitalone.com/privacy";
export const FAQS_LINK = {
  capone: "https://www.capitalone.com/help-center/capital-one-travel/",
  "capone-corporate": PATH_CORP_FAQ,
};
